import { Injectable } from '@angular/core';
import { ResponseBeanModel } from '@core/models/responsebean.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CreditService } from '../../services/credit.service';
import { GetAllProductAction, GetCartDetailAction, PurchaseProductsAction, AddToCartAction, DeleteFromCartAction, PurchaseCartItemWithCredit, SelectedCartChanged, PaymentAlertPopupAction, PurchaseFreeTrialAction } from './credit.actions';
import { ProductOptionsModel } from '@core/models/product-options.model';
import { MyCartModel } from '@core/models/my-cart.model';
import { sortBy } from 'lodash';

export interface CreditStateModel {
    creditProducts: ProductOptionsModel[];
    error?: any;
    loading: boolean;
    link: string;
    personalityProducts: ProductOptionsModel[];
    myCart: MyCartModel[];
    dogCareProducts: ProductOptionsModel[];
    personCareProducts: ProductOptionsModel[];
    mediaProducts: ProductOptionsModel[];
    dogDiaryProducts: ProductOptionsModel[];
    allInclusiveProducts: ProductOptionsModel[];
}

const defaultState: CreditStateModel = {
    creditProducts: [],
    error: undefined,
    loading: false,
    link: '',
    personalityProducts: [],
    myCart: [],
    dogCareProducts: [],
    personCareProducts: [],
    mediaProducts: [],
    dogDiaryProducts: [],
    allInclusiveProducts: [],
};

@State<CreditStateModel>({
    name: 'credits',
    defaults: defaultState
})
@Injectable()
export class CreditState {

    @Selector()
    public static getState(state: CreditStateModel): CreditStateModel {
        return state;
    }

    @Selector()
    public static creditProducts(state: CreditStateModel): ProductOptionsModel[] {
        return state.creditProducts;
    }

    @Selector()
    public static getAllInclusiveProducts(state: CreditStateModel): any[] {
        return state.allInclusiveProducts;
    }

    @Selector()
    public static personalityProducts(state: CreditStateModel): ProductOptionsModel[] {
        return state.personalityProducts;
    }

    @Selector()
    public static link(state: CreditStateModel): string {
        return state.link;
    }

    @Selector()
    public static myCart(state: CreditStateModel): MyCartModel[] {
        return state.myCart;
    }

    @Selector()
    public static loading(state: CreditStateModel): boolean {
        return state.loading;
    }

    @Selector()
    public static error(state: CreditStateModel): any {
        return state.error;
    }

    @Selector()
    public static dogCareProducts(state: CreditStateModel): ProductOptionsModel[] {
        return state.dogCareProducts;
    }

    @Selector()
    public static personCareProducts(state: CreditStateModel): ProductOptionsModel[] {
        return state.personCareProducts;
    }

    @Selector()
    public static mediaProducts(state: CreditStateModel): ProductOptionsModel[] {
        return state.mediaProducts;
    }

    @Selector()
    public static dogDiaryProducts(state: CreditStateModel): ProductOptionsModel[] {
        return state.dogDiaryProducts;
    }

    constructor(
        private creditService: CreditService,
    ) { }

    @Action(GetAllProductAction)
    getAllProducts(ctx: StateContext<CreditStateModel>, action: GetAllProductAction): any {
        ctx.patchState({ loading: true });
        return this.creditService.getstaticProductDesc()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        const allInclusiveProducts = [] as any;
                        const creditProducts = [] as any;
                        const personalityProducts = [] as any;
                        const dogCareProducts = [] as ProductOptionsModel[];
                        const personCareProducts = [] as ProductOptionsModel[];
                        const mediaProducts = [] as ProductOptionsModel[];
                        const dogDiaryProducts = [] as ProductOptionsModel[];
                        response.data?.forEach((d: any) => {
                            switch (d.type) {
                                case 'pt':
                                    personalityProducts.push(d);
                                    break;
                                case 'credits':
                                    creditProducts.push(d);
                                    break;
                                case 'dogCare':
                                    dogCareProducts.push(d);
                                    break;
                                case 'personCare':
                                    personCareProducts.push(d);
                                    break;
                                case 'media':
                                    mediaProducts.push(d);
                                    break;
                                case 'mediatwo':
                                    dogDiaryProducts.push(d);
                                    break;
                                case 'allInclusivePurchase':
                                    allInclusiveProducts.push(d);
                                    break;
                            }
                        });                        
                        ctx.patchState({
                            creditProducts: sortBy(creditProducts, ['quantity']),
                            personalityProducts,
                            dogCareProducts,
                            personCareProducts,
                            mediaProducts,
                            dogDiaryProducts,
                            allInclusiveProducts,
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(PurchaseProductsAction)
    purchaseCredit(ctx: StateContext<CreditStateModel>, action: PurchaseProductsAction): any {
        ctx.patchState({ loading: true });
        return this.creditService.purchaseProduct(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            link: response.data,
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(PurchaseFreeTrialAction)
    initiateFreeTrial(ctx: StateContext<CreditStateModel>): any {
        ctx.patchState({ loading: true });
        return this.creditService.getFreeTrial()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err.error);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(AddToCartAction)
    addToCart(ctx: StateContext<CreditStateModel>, action: AddToCartAction): any {
        ctx.patchState({ loading: true });
        return this.creditService.addToCart(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(GetCartDetailAction)
    getCartDetail(ctx: StateContext<CreditStateModel>, action: GetCartDetailAction): any {
        ctx.patchState({ loading: true });
        return this.creditService.getCartDetail()
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            myCart: response.data,
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(DeleteFromCartAction)
    deleteProductFromCart(ctx: StateContext<CreditStateModel>, action: DeleteFromCartAction): any {
        ctx.patchState({ loading: true });
        return this.creditService.deleteFromCart(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            myCart: response.data,
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(PurchaseCartItemWithCredit)
    purchaseCartItemWithCredit(ctx: StateContext<CreditStateModel>, action: PurchaseCartItemWithCredit): any {
        ctx.patchState({ loading: true });
        return this.creditService.purchaseCartItemWithCredit(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(SelectedCartChanged)
    selectedCartChanged(ctx: StateContext<CreditStateModel>, action: SelectedCartChanged): any {
        ctx.patchState({ loading: true });
        return this.creditService.selectedCartChanged(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                        loading: false
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }

    @Action(PaymentAlertPopupAction)
    paymentAlertPopup(ctx: StateContext<CreditStateModel>, action: PaymentAlertPopupAction): any {
        ctx.patchState({ loading: true });
        return this.creditService.paymentAlertButton(action.model)
            .pipe(
                tap((response: ResponseBeanModel) => {
                    if (response.success) {
                        ctx.patchState({
                            error: undefined,
                            loading: false,
                        });
                    }
                }),
                catchError((err) => {
                    ctx.patchState({
                        error: err,
                    });
                    return throwError(err);
                }),
                finalize(() => {
                    ctx.patchState({ loading: false });
                })
            );
    }
}
