import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PersonTestRoutingModule } from './person-test-routing.module';

import { PersonProgressBarComponent } from './shared/components/person-progress-bar/person-progress-bar.component';
import { PersonTestPageOneComponent } from './components/person-test-page-one/person-test-page-one.component';
import { PersonTestPageTwoComponent } from './components/person-test-page-two/person-test-page-two.component';
import { PersonTestPageThreeComponent } from './components/person-test-page-three/person-test-page-three.component';
import { PersonTestPageFourComponent } from './components/person-test-page-four/person-test-page-four.component';
import { PersonTestPageFiveComponent } from './components/person-test-page-five/person-test-page-five.component';
import { PeopleTestInfoPopupComponent } from './components/people-test-info-popup/people-test-info-popup.component';
import { EntryDialogPerson } from './shared/dialog/entry-dialog';

import { AppCoreModule } from '@core/app-core.module';
import { MatDialogModule } from '@angular/material/dialog';
import { PersonTestComponent } from './person-test.component';


// import { RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
// import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
// import { environment } from 'environments/environment';
import { NgxsModule } from '@ngxs/store';
import { PersonTestState } from './store/person-test.state';
import { TestMainHeaderComponent } from './shared/components/test-main-header/test-main-header.component';
import { PawCheckboxControlComponent } from './shared/components/paw-checkbox-control/paw-checkbox-control.component';
// import { PersonTestResultModule } from './person-test-result/person-test-result.module';
import { ProfileSharedModule } from 'app/profile/shared/shared.module';
// import { UserProfileResultComponent } from '../profile/components/profile-user/components/user-profile-result/user-profile-result.component';

@NgModule({
  declarations: [
    PersonProgressBarComponent,
    PersonTestPageOneComponent,
    PersonTestPageTwoComponent,
    PersonTestPageThreeComponent,
    PersonTestPageFourComponent,
    PersonTestPageFiveComponent,
    PeopleTestInfoPopupComponent,
    PersonTestComponent,
    TestMainHeaderComponent,
    PawCheckboxControlComponent,
    PersonProgressBarComponent,
    // UserProfileResultComponent
  ],
  imports: [
    CommonModule,
    AppCoreModule,
    PersonTestRoutingModule,
    NgxsModule.forFeature([PersonTestState]), //PersonDetailState
    MatDialogModule,
    ProfileSharedModule,
    // PersonTestResultModule
  ],
  entryComponents: [
    // PersonTestComponent,
    // EntryDialogPerson
  ],
  providers: [
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: { siteKey: environment.siteKeyForCaptcha } as RecaptchaSettings,
    // },
    // {
    //   provide: RECAPTCHA_LANGUAGE,
    //   useValue: 'de',
    // },
  ]
})
export class PersonTestModule { }
