import { DogFilterModel } from '../models/dog-filter.model';
import { EditImageForCare } from '../models/edit-image.model';
import { FeedbackFormModel } from '../models/feedback-form.model';
import { PersonFilterModel } from '../models/person-filter.model';
import { SearchFormModel } from '../models/search-form-model';

export class SetActiveTabIndex {
    static readonly type = '[Care] Set Active Tab';
    constructor(public model: number) {
    }
}

export class SetMembershipCards {
    static readonly type = '[Care] Set Membership Cards';
    constructor(public model: boolean) {
    }
}

export class SetPersonOrDogForm {
    static readonly type = '[Care] Set Person Or Dog Form';
    constructor(public model: boolean | undefined) {
    }
}
export class SetCurrentStep {
    static readonly type = '[Care] Set Current Step';
    constructor(public model: number) {
    }
}

export class AddPersonForCare {
    static readonly type = '[Care] Add Person FOr Care';
    constructor(public model: any) {
    }
}

export class AddDogForCare {
    static readonly type = '[Care] Add Dog For Care';
    constructor(public model: any) {
    }
}

export class GetSinglePublication {
  static readonly type = '[Care] Get Single Publication';
  constructor(public model: {id: string, type: string}) {
  }
}

export class CareImageChange {
  static readonly type = '[Care] Image Change';
  constructor(public model: EditImageForCare) {
  }
}

export class CareImageDelete {
  static readonly type = '[Care] Image Delete';
  constructor(public model: EditImageForCare) {
  }
}

export class SetMainTabIndex {
  static readonly type = '[Care] Set Main Tab';
  constructor(public model: number) {
  }
}

export class GetAllMyPublications {
  static readonly type = '[Care] Get All Publications';
  constructor() {
  }
}

export class DeleteMyPublication {
  static readonly type = '[Care] Delete My Publication';
  constructor(public model: {id: string, type: string}) {
  }
}

export class SetNullDataAction {
  static readonly type = '[Care] Set Null Data';
  constructor() {
  }
}

export class EditDogForCare {
  static readonly type = '[Care] Edit Dog For Care';
  constructor(public model: {data: any , id: string}) {
  }
}

export class EditPersonForCare {
  static readonly type = '[Care] Edit Person For Care';
  constructor(public model: {data: any , id: string}) {
  }
}

export class SetFilterOptions {
  static readonly type = '[Care] Set Filter Options';
  constructor(public model: {filter: DogFilterModel | PersonFilterModel , type: string}) {
  }
}

export class SetSearchDataForm {
  static readonly type = '[Care] Set Search FromData';
  constructor(public model: SearchFormModel) {
  }
}
export class SetSearchTab {
  static readonly type = '[Care] Set Search Tab';
  constructor(public model: string) {
  }
}

export class SetSkipIndex {
  static readonly type = '[Care] Set Skip Index';
  constructor(public model: number) {
  }
}

export class SearchPersonOrDog {
  static readonly type = '[Care] Search Person Or Dog';
  constructor(public model: string) {
  }
}

export class AddToFavourite {
  static readonly type = '[Care] Add To Favourite';
  constructor(public model: {id: string, type: string, add: boolean, insideData?: boolean, index?: number}) {
  }
}

export class PublishMyPublication {
  static readonly type = '[Care] Publish My Publication';
  constructor(public model: {id: string, type: string}) {
  }
}

export class ActivationChangePublication {
  static readonly type = '[Care] Activation Change Publication';
  constructor(public model: {id: string, type: string, value: boolean}) {
  }
}

export class SetLoaderState {
  static readonly type = '[Care] Set Loader State';
  constructor(public model: boolean) {
  }
}

export class ScrollAction {
  static readonly type = '[Care] Scroll Action';
  constructor(public model: {id: string, desktop: number, mobile: number}) {
  }
}

export class FeedbackFormSubmit {
  static readonly type = '[Care] Feedback Form Submit';
  constructor(public model: FeedbackFormModel) {
  }
}
