import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LoginModel } from 'app/auth/models/login.model';
import { environment } from 'environments/environment';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
@Injectable({
  providedIn: 'root',
})
export class UtilService {
  public emailRegex: RegExp =
    /^([A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9]))$/;

  constructor(private cookieService: CookieService) {}

  public setKeyValue(key: string, value: any): void {
    const date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000); // 15 days
    console.log('environment.production', environment.production);
    let domainName = '.fair-dogs.com';
    if (environment.production) {
      console.log('production INN');
      domainName = 'fair-dogs.com';
    }
    const options = {
      expires: date,
    } as CookieOptions;
    console.log('location.href', window.location.href);
    if (!window.location.href.includes('localhost')) {
      options.domain = domainName;
    }
    this.cookieService.put(key, value, options);
  }

  public getValue(key: string): any {
    return this.cookieService.get(key);
  }

  public removeKey(key: string): any {
    let domainName = '.fair-dogs.com';
    if (environment.production) {
      domainName = 'fair-dogs.com';
    }
    const options = {} as CookieOptions;
    if (!window.location.href.includes('localhost')) {
      options.domain = domainName;
    }
    this.cookieService.remove(key, options);
  }

  public getCredentialFromCookie(): LoginModel {
    const email = this.getValue('email');
    const password = this.getValue('password');
    return {
      email,
      password,
      rememberMe: email && password ? true : false,
    } as LoginModel;
  }

  public setCredentialToCookie(data: any): void {
    this.setKeyValue('email', data.email);
    this.setKeyValue('password', data.password);
  }

  public onlyNumber(
    event: any,
    type?: string,
    allowMinus = false,
    alllowDecimal = false
  ): boolean {
    if (type === 'time') {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        return false;
      }
      return true;
    } else {
      const charCode = event.which ? event.which : event.keyCode;
      if (allowMinus) {
        if (charCode === 45) {
          return true;
        }
      }
      if (alllowDecimal) {
        if (
          charCode !== 46 &&
          charCode > 31 &&
          (charCode < 48 || charCode > 57)
        ) {
          return false;
        }
        return true;
      }
      if (charCode !== 47 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
  }

  public usernameValidator(control: FormControl): { [key: string]: any } | null {
    if (control && control.value) {
      const value: string = control.value;
      
      // Check for conditions
      if (value && ((value.match(/\d/g) || []).length > 4 || value.includes('@') || value.includes('(at)') || value.includes('.ch') || value.includes('.com'))) {
        return { 'isNumberSymbolError': true };
      }
    }

    return null; // Valid username
  }

  public dognameValidator: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control && control.value) {
      const name = control.value ? control.value?.toLowerCase() : control.value;
      const containsDigits = (name.match(/\d/g) || []).length > 4; // Check if name contains more than 4 digits
      const containsSymbol = name.includes('@'); // Check if name contains @ symbol
  
      if (containsDigits || containsSymbol) return { invalidName: true };
    }

    return null;
  };

  public containContactInfoValidator: ValidatorFn = (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control && control.value) {
      const name = control.value ? control.value?.toLowerCase() : control.value;
      const containContactInformation = name.includes('(at)') || name.includes('.ch') || name.includes('.com');
      if (containContactInformation) return { containContactInfo: true }
    }
    return null;
  };
}
