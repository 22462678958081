import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-person-progress-bar',
  templateUrl: './person-progress-bar.component.html',
  styleUrls: ['./person-progress-bar.component.scss']
})
export class PersonProgressBarComponent implements OnInit {
  @Input() value!: number;
  constructor() { }

  ngOnInit(): void {
  }

}
