import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { UserModel } from '@core/models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as countries from 'assets/data/countries.json';
import { SpinnerService } from '@core/services/spinner.service';
import { Store } from '@ngxs/store';
import { EditUserProfileAction, HeaderValueChange, LoadUserAction, SetImageAction } from '../../store/user/user.actions';
import { UserState } from '../../store/user/user.state';
import { EditUserProfileModel } from '@core/models/edit-user-profile.model';
import { EditCoverImageComponent } from '../edit-cover-image/edit-cover-image.component';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AddressDialogComponent } from '../address-dialog/address-dialog.component';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AddressService } from '../../services/address.service';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import { MapsAPILoader } from '@agm/core';
import { UtilService } from '@core/services/util.service';

export const MY_FORMATS = {
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EditUserProfileComponent implements OnInit {
  public user!: UserModel;
  public formGroup: FormGroup = {} as FormGroup;
  public imageName: string | null | undefined = '';
  public selectedBirthDay: any;
  public countriesData: Array<any> = [];
  public genderData = [
    { name: 'Weiblich', value: 'female' },
    { name: 'Männlich', value: 'male' },
    { name: 'Divers', value: 'other' }
  ];
  public languageData = [
    { name: 'Deutsch', value: 'de' },
    // { name: 'English', value: 'en' }
  ];
  public maxDate = new Date();
  public isSuggestedSelect = false;
  public notFoundAddress = false;
  public isSameCountry = true;
  placeOptions: any[] = [];
  @ViewChild('addressInput')
  addressInput!: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditUserProfileComponent>,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private spinner: SpinnerService,
    private store: Store,
    private addressService: AddressService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.countriesData = (countries as any).default;
    this.user = this.data.user;

    this.initForm(this.user);
    this.updateValidations(this.data.requiredField);
    // @ts-ignore: Object is possibly 'null'.
    this.formGroup
      .get('address')
      .valueChanges.
      pipe(
        debounceTime(500))
      .subscribe((value) => {
        if (value) {
          this.getPlaceAutocompleteCustom();
          // this.getPlaceAutocomplete();
          if (this.isSuggestedSelect) {
            this.notFoundAddress = false;
            this.isSuggestedSelect = false;
          } else {
            this.notFoundAddress = true;
            this.setLocationNull();
          }
        } else {
          this.placeOptions = [];
          this.setLocationNull();
        }
      });

    // @ts-ignore: Object is possibly 'null'.
    this.formGroup
      .get('date')
      .valueChanges
      .subscribe((values) => {
        if (!this.formGroup.controls.date.errors) {
          const value = values.split('.').join('/');
          const enter = moment(value, 'DD/MM/YYYY');
          if (enter.isValid()) {
            const now = moment(new Date());
            if (now.isSameOrAfter(enter, 'day')) {
              this.formGroup.patchValue({
                birthDay: value
              });
              const bdayArray = values.split('.').map((b: string) => Number(b));
              this.selectedBirthDay = moment([bdayArray[2], bdayArray[1] - 1, bdayArray[0]]);
              this.formGroup.controls.date.setErrors(null);
            } else {
              this.setValidDateError();
            }
          } else {
            this.setValidDateError();
          }
        } else {
          this.setValidDateError();
        }
      });

    // @ts-ignore: Object is possibly 'null'.
    this.formGroup
      .get('userName')
      .valueChanges
      .subscribe((value) => {
        if (value.includes('fairDogs') || value.includes('fair-dogs') || value.includes('FAIR DOGS')) {
          this.formGroup.controls.userName.setErrors({ invalidUserName: 'invalid user name' });
        } else {
          // this.formGroup.controls.userName.setErrors({invalidUserName: null});
        }
      });

  }

  get fg(): any {
    return this.formGroup.controls;
  }

  setLocationNull(): void {
    this.formGroup.patchValue({
      placeId: null,
      latitude: null,
      longitude: null,
      city: null,
      countryName: null,
      state: null
    });
  }

  private getPlaceAutocomplete(): void {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.addressInput.nativeElement,
        {
          componentRestrictions: { country: [this.formGroup.controls.country.value] },
        });
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        this.ngZone.run(() => {
          const address = autocomplete.getPlace() as Address;
          this.isSuggestedSelect = true;
          this.isSameCountry = true;
          const inputValue = this.addressInput.nativeElement.value;
          const locationObj: { [key: string]: any } = this.addressService.fillAddressRelatedFields(address);
          Object.keys(locationObj).forEach((keyname: string) => {
            this.formGroup.patchValue({
              [keyname]: locationObj[keyname]
            });
          });
          this.formGroup.patchValue({
            address: inputValue,
          });
        });
      });
    });
  }

  private getPlaceAutocompleteCustom(): void {
    this.mapsAPILoader.load().then(() => {
      const service = new google.maps.places.AutocompleteService();
      const request = {
        input: this.formGroup.value.address,
        componentRestrictions: { country: this.formGroup.value.country },
      };
      service.getPlacePredictions(request, (places) => {
        this.placeOptions = places;
      });
    });
  }

  placeSelect(id: string): void {
    const map = new google.maps.Map(document.createElement('div'));
    this.ngZone.run(() => {
      const service = new google.maps.places.PlacesService(map);
      const placeDetails = service.getDetails(
        { placeId: id },
        (result, status) => {
          this.ngZone.run(() => {
            const address = result as Address;
            this.isSuggestedSelect = true;
            this.isSameCountry = true;
            const inputValue = this.addressInput.nativeElement.value;
            const locationObj: { [key: string]: any } = this.addressService.fillAddressRelatedFields(address);
            Object.keys(locationObj).forEach((keyname: string) => {
              this.formGroup.patchValue({
                [keyname]: locationObj[keyname]
              });
            });
            this.formGroup.patchValue({
              address: inputValue,
            });
          });
        }
      );
    });
  }

  // get languagesKnown(): FormArray {
  //   return this.formGroup.get('languagesKnown') as FormArray;
  // }

  dateChange(date: Date): void {
    this.formGroup.patchValue({
      date: moment(date).format('L')
    });
  }

  setValidDateError(): void {
    this.formGroup.patchValue({
      birthDay: ''
    });
    this.formGroup.controls.date.setErrors({ wrongDate: 'please select valid date' });
  }

  initForm(user: UserModel): void {
    this.imageName = user.profilePhoto ? user.profilePhoto : 'assets/img/img_person_placeholder_web.svg';
    const bdayArray = user.birthDay ? user.birthDay.split('.').map(b => Number(b)) : [];
    this.selectedBirthDay = user.birthDay ? moment([bdayArray[2], bdayArray[1] - 1, bdayArray[0]]) : '';
    this.formGroup = this.formBuilder.group({
      firstName: [user.firstName, [Validators.required, Validators.maxLength(30), Validators.minLength(2), Validators.pattern('[a-zA-Z-]*')]],
      lastName: [user.lastName, [Validators.required, Validators.maxLength(30), Validators.minLength(2), Validators.pattern('[a-zA-Z-]*')]],
      userName: [{ value: user.userName || null, disabled: user.userName ? true : false }, [Validators.maxLength(30), this.utilService.usernameValidator]],
      gender: [user.gender || '', Validators.required],
      address: [user?.location?.address || ''],
      birthDay: user.birthDay ? new Date(user.birthDay) : null,
      country: [user.country, Validators.required],
      latitude: [user?.location?.latitude || ''],
      longitude: [user?.location?.longitude || ''],
      placeId: [user?.location?.placeId || ''],
      countryName: [user?.location?.countryName || ''],
      city: [user?.location?.city || ''],
      state: [user?.location?.state || ''],
      date: [user?.birthDay || '', [Validators.required]],
      // languagesKnown: this.formBuilder.array([
      // ]),
      preferLanguage: [user.preferLanguage || 'de', Validators.required]
    });

    // if (user.languagesKnown.length > 0) {
    //   user.languagesKnown.forEach(element => {
    //     this.languagesKnown.push(this.formBuilder.control(element));
    //   });
    // }
  }

  // onAddressChange(address: Address): void {
  //   this.isSuggestedSelect = true;
  //   const inputValue = this.addressInput.nativeElement.value;
  //   const locationObj: {[key: string]: any} = this.addressService.fillAddressRelatedFields(address);
  //   Object.keys(locationObj).forEach((keyname: string) => {
  //     this.formGroup.patchValue({
  //       [keyname]: locationObj[keyname]
  //     });
  //   });
  //   this.formGroup.patchValue({
  //     address: inputValue,
  //   });
  // }

  onClose(): void {
    this.dialogRef.close();
  }

  countryChange(event: any): void {
    const countryName = this.formGroup.value.countryName;
    this.isSameCountry = countryName && countryName === event.code.toUpperCase() ? true : false;
    this.placeOptions = [];
    // this.formGroup.patchValue({
    //     address: null,
    //     placeId: null,
    //     latitude: null,
    //     longitude: null,
    //     city: null,
    //     countryName: null,
    //     state: null
    //   });
  }

  // resetPicker(): void {
  //   this.formGroup.patchValue({
  //     birthDay: ''
  //   });
  // }

  // addNewlanguage(): void {
  //   this.languagesKnown.push(this.formBuilder.control(''));
  // }

  // removeLanguage(i: number): void {
  //   this.languagesKnown.removeAt(i);
  // }

  addressPopup(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // dialogConfig.scrollStrategy = this.overlay.scrollStrategies.reposition();
    const { address, latitude, longitude, placeId, city, state, countryName, country } = this.formGroup.value;
    dialogConfig.data = {
      location: {
        address,
        latitude,
        longitude,
        placeId,
        city,
        state,
        countryName,
        country
      }
    };

    const dialogRef = this.dialog.open(AddressDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.location) {
        // tslint:disable-next-line: no-shadowed-variable
        const { address, latitude, longitude, placeId, city, countryName, state } = result.location;
        this.isSuggestedSelect = true;
        this.formGroup.patchValue({
          address,
          longitude,
          latitude,
          placeId,
          city,
          state,
          countryName
        });
        this.isSameCountry = countryName === country.toUpperCase() ? true : false;
      }
    });
  }
  // onPhotoChange(event: any): void{
  //   const file = event.target.files[0];
  //   if (file){
  //     this.store.dispatch(new SetImageAction(file, 1)).subscribe(
  //       () => {
  //         this.imageName = (this.store.selectSnapshot(UserState.user) as UserModel).profilePhoto;
  //       },
  //       () => {
  //       }
  //     );
  //   }
  // }

  onEditProfileImage(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // dialogConfig.scrollStrategy = this.overlay.scrollStrategies.reposition();
    dialogConfig.data = {
      coverImageWeb: this.imageName,
      title: 'PAGE.SHARED.EDIT_USER_PROFILE.PROFILE_IMAGE_DIALOG_TITLE',
      aspectWidth: 4,
      aspectHeight: 4,
      roundCropper: true
    };
    dialogConfig.panelClass = 'c--img-upload';
    const dialogRef = this.dialog.open(EditCoverImageComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result?.croppedImage) {
        if (this.user._id) {
          this.spinner.show();
          const { croppedImage } = result;
          this.store.dispatch(new SetImageAction(croppedImage, 1)).subscribe(
            () => {
              this.imageName = (this.store.selectSnapshot(UserState.user) as UserModel).profilePhoto;
            },
            () => {
            }
          );
        }
      }
    });
  }

  onSubmit(): void {
    if (this.formGroup.invalid || (this.formGroup.controls.address.value && (this.notFoundAddress || !this.isSameCountry))) {
      return;
    }

    const { address, latitude, longitude, placeId, firstName, lastName, userName
      , gender, country, preferLanguage, city, countryName, state } = this.formGroup.value;
    const user = {
      firstName, lastName, userName, gender, country, preferLanguage,
      birthDay: this.formGroup.value.date,
      location: {
        address,
        latitude,
        longitude,
        placeId,
        city,
        countryName,
        state
      }
    };
    this.store.dispatch(new EditUserProfileAction(user as EditUserProfileModel)).subscribe(
      () => {
        this.store.dispatch(new HeaderValueChange()); // change profile val call for manage header dropdown val

        this.store.dispatch(new LoadUserAction()).subscribe(
          () => {
            this.dialogRef.close('submitted');
          },
          () => { },
        );
      },
      (error: any) => {
        if (!error.error.success) {
          this.spinner.hide();
          const errKey = Object.keys(error.error.error.keyPattern)[0];
          const errMsg = error.error.message;
          if (errKey=="userName") {
            this.formGroup.controls[errKey].setErrors({ 'duplicatedUserName': true });
          } else if (errKey && errMsg) {
            this.formGroup.controls[errKey].setErrors({ errMsg });
          }
        }
      }
    );
  }


  updateValidations(from : any){
    this.formGroup.controls['gender'].clearValidators();
    if(from === "DogAd"){
      this.formGroup.controls['address'].setValidators([
        Validators.required,
      ]);
      this.formGroup.controls['address'].updateValueAndValidity();
    }
    else if(from === "PersonAd"){
      this.formGroup.controls['gender'].setValidators([
        Validators.required,
      ]);
      this.formGroup.controls['address'].setValidators([
        Validators.required,
      ]);
      this.formGroup.controls['birthDay'].setValidators([
        Validators.required,
      ]);
      this.formGroup.controls['gender'].updateValueAndValidity();
      this.formGroup.controls['address'].updateValueAndValidity();
      this.formGroup.controls['birthDay'].updateValueAndValidity();
    }
    else if(from === "PersonalityTest"){
      this.formGroup.controls['gender'].setValidators([
        Validators.required,
      ]);
      this.formGroup.controls['gender'].updateValueAndValidity();
    }
    else{
      this.formGroup.controls['gender'].setValidators([
        Validators.required,
      ]);
      this.formGroup.controls['gender'].updateValueAndValidity();
    }
  }

}
