import { AddToCartModel } from '@core/models/add-to-cart.model';

export class GetAllProductAction {
    static readonly type = '[Credits] Get All Products';
    constructor() { }
}
export class PurchaseProductsAction {
    static readonly type = '[Credits] Purchase Credits';
    constructor(public model: any) { }
}

export class PurchaseFreeTrialAction {
    static readonly type = '[Credits] Initiate Free Trial';
    constructor() { }
}

export class AddToCartAction {
    static readonly type = '[Credits] Purchase With Credits';
    constructor(public model: AddToCartModel) { }
}

export class GetCartDetailAction {
    static readonly type = '[Credits] Get Cart Detail';
    constructor() { }
}

export class DeleteFromCartAction {
    static readonly type = '[Credits] Delete Cart Product';
    constructor(public model: string) { }
}

export class PurchaseCartItemWithCredit {
    static readonly type = '[Credits] Purchase Cart With Credit';
    constructor(public model: {productsArray: string[]}) { }
}

export class SelectedCartChanged {
    static readonly type = '[Credits] Cart Item Change';
    constructor(public model: {isChecked: boolean, id: string}) { }
}

export class PaymentAlertPopupAction {
    static readonly type = '[Credits] Popup Alert Action';
    constructor(public model: any) { }
}