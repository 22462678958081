import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ChatToastComponent } from '@core/components/chat-toast.component';
import { IReceiveMessage } from '@core/interfaces';
import { AuthService } from 'app/auth/services/auth.service';
import { cloneDeep } from 'lodash';
import { GlobalConfig, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrNotificationService {

  private toasterOptions!: GlobalConfig;
  private lastInserted: number[] = [];

  constructor(
    @Inject(PLATFORM_ID) public platformId: object,
    private toastr: ToastrService,
    private authService: AuthService,
  ) {
    this.toasterOptions = this.toastr.toastrConfig;
  }

  showSuccess(message: string, title: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.toastr.success(message, title);
    }
  }

  showError(message: string, title: string): void {
    if (isPlatformBrowser(this.platformId)) {
      this.toastr.error(message, title);
    }
  }

  showHttpError(error: HttpErrorResponse): void {
    if (isPlatformBrowser(this.platformId)) {
      if (error.error) {
        if (!error.error.success) {
          if (error.status === 401) {
            this.showError('', error.error.message);
            setTimeout(() => {
              this.authService.logout();
            }, 3000);
          } else if (error.status === 400) {
            this.showError('', error.error.message);
          }
        }
      } else {
        this.showError('Something went wrong', 'Error');
      }
    }
  }

  showChatNotification(receiveMessageObject: IReceiveMessage) {
    if (isPlatformBrowser(this.platformId)) {
      const options = cloneDeep(this.toasterOptions);
      options.toastComponent = ChatToastComponent;
      options.messageClass = 'chat-message';
      options.closeButton = true;
      options.disableTimeOut = true;
      options.enableHtml = true;
      options.maxOpened = 0;
      options.tapToDismiss = false;

      // Do not need to show more than 1 motification at a time
      if(this.lastInserted.length > 0) return;

      const inserted = this.toastr.show(undefined, undefined, options);

      // Used when click on notification itself
      // inserted.onTap.subscribe((action) => {
      // });
      inserted.onHidden.subscribe((action) => {
        this.lastInserted.pop();
      })
      inserted.onAction.subscribe((action) => {
        window.open(`/chat?user=${receiveMessageObject?.fromUser?._id}`, "_blank");
      });

      if (inserted && inserted.toastId) this.lastInserted.push(inserted.toastId);
      return inserted;
    }

    return null;
  }

}
