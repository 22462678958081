import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SendOtpModel } from '@core/models/send-otp.model';
import { TwoStepAuthModel } from '@core/models/two-step-auth.model';
import { BaseApiService } from '@core/services/base-api.service';
import { UtilService } from '@core/services/util.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ForgotPasswordModel } from '../models/forgot-password.model';
import { LoginModel } from '../models/login.model';
import { RegisterEmailModel } from '../models/register-email.model';
import { ResetPasswordModel } from '../models/reset-password.model';
import { io, Socket } from 'socket.io-client';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseApiService {
    socket!: Socket;
    constructor(
        protected http: HttpClient,
        private utilService: UtilService,
        private router: Router,
    ) {
        super(http);
        this.apiEndpoint = 'user'; // PATH to controller or microservice
    }

    login(model: LoginModel): Observable<any> {
        return this.makeRequest('POST', 'login', model).pipe(
            tap((response) => {
                if (response.success && !response?.data?.user?.twoStepAuthOn) {
                    this.storeAuthResponse(response.data);
                    let currentUser = response.data?.user;
                    if (currentUser?.role === 'admin') {
                        currentUser = { ...currentUser, adminToken: { token: response?.data?.token } };
                    }
                    const tempCurrUser = JSON.stringify(currentUser)
                    localStorage.setItem('currentUser', tempCurrUser); // for manage master access
                    this.utilService.setKeyValue('role', currentUser?.role);
                    return response;
                }
            })
        );
    }

    sendOtp(model: SendOtpModel): Observable<any> {
        return this.makeRequest('POST', 'sendOtp', model);
    }

    verifyOtp(model: TwoStepAuthModel): Observable<any> {
        return this.makeRequest('POST', 'verifyOtp', model).pipe(
            tap((response) => {
                if (response.success && response?.data?.user?.twoStepAuthOn) {
                    this.storeAuthResponse(response.data);
                    return response;
                }
            })
        );
    }

    registerEmail(model: RegisterEmailModel): Observable<any> {
        return this.makeRequest('POST', 'register', model);
    }

    resendEmail(model: ForgotPasswordModel): Observable<any> {
        return this.makeRequest('POST', 'resendEmail', model);
    }

    verifyEmail(model: object): Observable<any> {
        return this.makeRequest('GET', 'verifyEmail', model);
        // return of({userExist:true,isVerified:true});
    }

    checkEmailStatus(model: object): Observable<any> {
        // return of({userExist:true,isVerified:true});
        return this.makeRequest('POST', 'checkEmailStatus', model);
    }

    registerUser(model: any): Observable<any> {
        return this.makeRequest('PATCH', 'completeRegistration', model).pipe(
            tap((response) => {
                if (response.success) {
                    this.storeAuthResponse(response.data);
                    return response;
                }
            })
        );
    }

    forgotPassword(model: ForgotPasswordModel): Observable<any> {
        return this.makeRequest('POST', 'forgotPassword', model);
    }

    verifyResetpasswordTokenAction(model: string): Observable<any> {
        return this.makeRequest('POST', 'validateToken', { token: model });
    }

    resetPassword(model: ResetPasswordModel, token: string): Observable<any> {
        return this.makeRequest('POST', 'resetPassword?token=' + token, model).pipe(
            tap((response) => {
                if (response.success) {
                    this.storeAuthResponse(response.data);
                    return response;
                }
            })
        );
    }

    logout(): void {
        this.removeToken();
        this.utilService.removeKey('role');
        this.utilService.removeKey('currentUserId');
        localStorage.removeItem('currentUser');
        // this.socket.disconnect();
        // this.router.navigateByUrl('/', { skipLocationChange: true })
        //       .then(() => this.router.navigateByUrl('/login'));
        // location.reload();
        // window.location.href = "/";
        this.utilService.setKeyValue('isLogout', "true");
        window.location.href = environment.wpApiUrl;
        // this.router.navigate(['/auth/login']);
    }

    logoutToForgot(): void {
        this.removeToken();
        this.router.navigate(['/forgot-password']);
    }

    isAuthenticated(): boolean {
        return !!this.utilService.getValue('token');
    }

    public storeAuthResponse(response: any): void {
        this.saveToken(response.token);
    }

    public getToken(): string {
        return this.utilService.getValue('token');
    }

    private saveToken(token: string): void {
        this.utilService.setKeyValue('token', token);
    }

    private removeToken(): void {
        this.utilService.removeKey('token');
    }
}
