import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class SEOService extends BaseApiService {
  MetaData: any;
  seoData: any;

  constructor(
    private titleSvc: Title,
    private metaSvc: Meta,
    protected http: HttpClient
  ) {
    super(http);
    this.apiEndpoint = 'user';
  }

  public updateMetaTag(metaData: any): void {
    // console.log(meta.meta.?og_title);
    const { meta } = metaData
    // console.log('25', meta.?og_title);


    this.metaSvc.updateTag({ property: 'og:title', content: meta?.og_title });
    this.metaSvc.updateTag({ property: 'og:url', content: meta?.og_url });
    this.metaSvc.updateTag({ property: 'og:description', content: meta?.og_description });

    this.metaSvc.updateTag({ name: 'twitter:title', content: meta?.og_title });
    this.metaSvc.updateTag({ name: 'twitter:description', content: meta?.og_description });
  }

  getMetaTags(): Observable<any> {
    return this.makeRequest('GET', 'getMetaTags', {})
  }

  public addMetaProperty(metaData: any): void {
    const { meta } = metaData;
    // console.log('43', meta.og_url[0]);
    // this.metaSvc.removeTag("name='description'");
    // this.metaSvc.removeTag("name='keywords'")

    // setTimeout(() => {
    //   this.metaSvc.addTag({ name: 'description', content: meta.og_description }, true);
    //   this.metaSvc.addTag({ name: 'keywords', content: meta.og_url[0] }, true)
    // }, 2000);

    this.metaSvc.updateTag({ name: 'description', content: meta?.og_description });
    this.metaSvc.updateTag({ name: 'keywords', content: meta?.og_url[0] })
  }

  setTitle(title: string) {
    this.titleSvc.setTitle(title);
  }
}
